// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/data/jenkins/workspace/prod-front-pxxtech-com/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__index' */'@/layouts/index'), loading: LoadingComponent}),
    "routes": [
      {
        "key": "home",
        "path": "/index.html",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'@/pages/home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "key": "service",
        "path": "/service(.html)?",
        "routes": [
          {
            "key": "managerSystem",
            "path": "/service/managerSystem.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__service__managerSystem' */'@/pages/service/managerSystem'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "key": "workPlatform",
            "path": "/service/workPlatform.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__service__workPlatform' */'@/pages/service/workPlatform'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "key": "solution",
        "path": "/solution(.html)?",
        "routes": [
          {
            "key": "solution00",
            "path": "/solution.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__solution' */'@/pages/solution'), loading: LoadingComponent}),
            "group": "制造业",
            "query": {
              "index": 0,
              "cindex": 0
            },
            "exact": true
          },
          {
            "key": "solution01",
            "path": "/solution.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__solution' */'@/pages/solution'), loading: LoadingComponent}),
            "group": "制造业",
            "query": {
              "index": 0,
              "cindex": 1
            },
            "exact": true
          },
          {
            "key": "solution10",
            "path": "/solution.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__solution' */'@/pages/solution'), loading: LoadingComponent}),
            "group": "物流业",
            "query": {
              "index": 1,
              "cindex": 0
            },
            "exact": true
          },
          {
            "key": "solution11",
            "path": "/solution.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__solution' */'@/pages/solution'), loading: LoadingComponent}),
            "group": "物流业",
            "query": {
              "index": 1,
              "cindex": 1
            },
            "exact": true
          },
          {
            "key": "solution20",
            "path": "/solution.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__solution' */'@/pages/solution'), loading: LoadingComponent}),
            "group": "商贸流通业",
            "query": {
              "index": 2,
              "cindex": 0
            },
            "exact": true
          }
        ]
      },
      {
        "key": "about",
        "path": "/about(.html)?",
        "routes": [
          {
            "key": "style",
            "path": "/about/style.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__style' */'@/pages/about/style'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "key": "news",
            "path": "/about/news.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__news' */'@/pages/about/news'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "key": "join",
            "path": "/about/join.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__join' */'@/pages/about/join'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "key": "news_detail",
            "path": "/about/news/detail.html",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__news__detail' */'@/pages/about/news/detail'), loading: LoadingComponent}),
            "hideInMenu": true,
            "exact": true
          }
        ]
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
