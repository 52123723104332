import * as React from 'react';
import styled from 'styled-components';
import { PageLoading } from '@parallel-line/web';

const Page = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default (props: React.PropsWithChildren<{}>) => (
  <Page>
    <PageLoading />
  </Page>
);
